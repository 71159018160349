export const environment = {
  name: 'uat',
  firebase: {
    apiKey: 'AIzaSyAh9g_hXXD262EegmECsChdUVu7h92iBPg',
    authDomain: 'uat-open-eat.firebaseapp.com',
    projectId: 'uat-open-eat',
    storageBucket: 'uat-open-eat.appspot.com',
    messagingSenderId: '622693648553',
    appId: '1:622693648553:web:57065c0c7bdf2580bb81b8',
    measurementId: 'G-2XDBXQ8N59',
  },
  recaptchaEnterpriseSiteKey: '6LeO6FUpAAAAAG_v8Zh_p1wkvlE3rSK1V-3ipe9c',
  axeptioSettings: {
    clientId: '6745d2f9d758012e2226f255',
    cookiesVersion: 'site beneficiaire openeat-fr_UAT',
  },
};
